import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Box, Flex } from '@rebass/grid';

import ServicePage from 'templates/servicePage';
import Usps from 'components/layout/usps';
import Container from 'components/grid/container';
import MaxWidth from 'components/grid/maxWidth';
import WysiwygArea from 'components/content/wysiwygArea';
import AppStoreLink from 'components/links/appStoreLink';

const Border = styled(Box)`
  border-bottom: 1px solid ${p => p.theme.greyLight};
`;

const GreyContainer = styled(Flex)`
  background-color: ${p => p.theme.greyBg};
  text-align: center;
`;

const iosApp = ({ data }) => {
  const {
    markdownRemark: { 
      frontmatter: { 
        hero,
        usps: { list: uspsList },
      },
      html,
    },
  } = data;

  const uspsArray = Object.keys(uspsList).map(i => uspsList[i]);

  return (
    <ServicePage 
      data={data}
      heroImage={<Img fluid={hero.image.src.childImageSharp.fluid} alt={hero.image.alt} />}
    >
      <Usps usps={uspsArray} />
      
      <Container pb={[10, 10, `100px`]}>
        <Border mb={[7, 7, `100px`]} />
        <MaxWidth>
          <WysiwygArea>
            {html}
          </WysiwygArea>
        </MaxWidth>
      </Container>

      <GreyContainer justifyContent="center" py={[10, 10, `100px`]}>
        <AppStoreLink height="80" />
      </GreyContainer>
    </ServicePage>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...LayoutFragment
      ...ServicePageFragment
      ...ServiceHeroFragment
      ...UspsFragment
      html
    }
  }
`;

iosApp.propTypes = {
  data: PropTypes.object.isRequired,
};

export default iosApp;
