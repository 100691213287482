import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import GridRow from 'components/grid/gridRow';
import GridItem from 'components/grid/gridItem';
import Heading from 'components/typography/heading';
import BasicTeaser from 'components/teasers/basicTeaser';

const Usps = ({ title, usps }) => {
  return (
    <GridRow
      title={title && <Heading rank="h2">{title}</Heading>}
      gridWrapper="ul"
      gridItems={(
        usps && usps.map((usp) => {
          return (
            <GridItem key={usp.title} as="li" width={[1, 1, 1, 4 / 12]}>
              <BasicTeaser
                title={usp.title}
                subtitle={usp.subtitle}
              />
            </GridItem>
          );
        })
      )}
    />
  );
};

export default Usps;

Usps.propTypes = {
  title: PropTypes.string,
  usps: PropTypes.array.isRequired,
};

export const query = graphql`
  fragment UspsFragment on MarkdownRemark {
    frontmatter {
      usps {
        title
        list {
          one {
            title
            subtitle
          }
          two {
            title
            subtitle
          }
          three {
            title
            subtitle
          }
        }
      }
    }
  }
`;